<template>
  <div>
    <div class="card" :class="$style.container">
      <div class="text-dark font-size-24 mb-4">
        <strong>Reset Password</strong>
      </div>
      <div v-show="show" class="alert alert-danger alert-dismissible fade show" role="alert">
        <span v-for="(err, index) in validation_errors" :key="index">{{ err[0] }} <br/></span>
      </div>
      <a-form class="mb-4" :form="form">
        <a-form-item>
          <input type="password" v-model="password" v-validate="{ required:true, min:6, max: 10 }" ref="password" id="password" name="password" class="form-control" placeholder="Password"/>
          <div class="invalid-feedback">Password is required</div>
        </a-form-item>
        <a-form-item>
          <input type="password" v-model="password_confirmation"  v-validate="{ required:true, confirmed:password }" id="password_confirmation" name="password_confirmation" class="form-control" placeholder="Enter confirm password"/>
          <div class="invalid-feedback">Confirm password is required and not match</div>
        </a-form-item>
        <a-button size="large" type="primary" class="text-center w-100" :loading="loader"  @click.prevent="forgotPassword"><strong>Reset my password</strong></a-button>
      </a-form>
      <router-link to="/auth/login" class="kit__utils__link font-size-16"><i class="fe fe-arrow-left mr-1 align-middle" />Go to Sign in</router-link>
    </div>
  </div>
</template>
<script>
import apiClient from '@/services/axios'

export default {
  name: 'CuiForgotPassword',
  data: function () {
    return {
      form: this.$form.createForm(this),
      password: '',
      password_confirmation: '',
      validation_errors: {},
      show: false,
      loader: false,
    }
  },
  methods: {
    forgotPassword() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.loader = true
          const userName = this.$route.params.user_name
          const verificationCode = this.$route.params.verification_code
          apiClient.post('api/users/forgot-password', {
            password: this.password,
            password_confirmation: this.password_confirmation,
            user_name: userName,
            verification_code: verificationCode,
          })
            .then(response => {
              this.loader = false
              if (!response.data.error) {
                this.$router.push({ name: 'authLogin' })
              } else {
                this.$notification.error({
                  message: response.data.message,
                })
              }
            })
            .catch(error => {
              this.loader = false
              this.show = true
              this.validation_errors = error.response.data.errors
              this.hide()
            })
        }
      })
    },
    hide() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
  },
}
</script>
<style lang="scss" module>
@import "@/components/cleanui/system/Auth/style.module.scss";
</style>
